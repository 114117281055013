var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { LLMApi, Configuration } from 'agentcreator';
var apiConfig = new Configuration({ basePath: 'https://creator.callyour.ai/api/v1' });
var AddLLMForm = function (_a) {
    var open = _a.open, onClose = _a.onClose, onSave = _a.onSave;
    var _b = useState(''), vendor = _b[0], setVendor = _b[1];
    var _c = useState(''), model = _c[0], setModel = _c[1];
    var _d = useState(false), requiresAPIKey = _d[0], setRequiresAPIKey = _d[1];
    var _e = useState(false), accessibleByAllUsers = _e[0], setAccessibleByAllUsers = _e[1];
    var _f = useState(''), host = _f[0], setHost = _f[1];
    var _g = useState(false), snackbarOpen = _g[0], setSnackbarOpen = _g[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var llmApi, newLLM, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    llmApi = new LLMApi(apiConfig);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, llmApi.createLLMConfiguration({
                            lLM: { vendor: vendor, model: model, requiresAPIKey: requiresAPIKey, accessibleByAllUsers: accessibleByAllUsers, host: host }
                        })];
                case 2:
                    newLLM = _a.sent();
                    onSave(newLLM);
                    setSnackbarOpen(true); // Open the snackbar on success
                    onClose();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseSnackbar = function () {
        setSnackbarOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, { open: open, onClose: onClose, children: [_jsx(DialogTitle, { children: "Add New LLM Configuration" }), _jsxs(DialogContent, { children: [_jsx(TextField, { autoFocus: true, margin: "dense", label: "Vendor", type: "text", fullWidth: true, variant: "outlined", value: vendor, onChange: function (e) { return setVendor(e.target.value); } }), _jsx(TextField, { margin: "dense", label: "Model", type: "text", fullWidth: true, variant: "outlined", value: model, onChange: function (e) { return setModel(e.target.value); } }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: requiresAPIKey, onChange: function (e) { return setRequiresAPIKey(e.target.checked); } }), label: "Requires API Key" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: accessibleByAllUsers, onChange: function (e) { return setAccessibleByAllUsers(e.target.checked); } }), label: "Accessible by All Users" }), _jsx(TextField, { margin: "dense", label: "Host (optional)", type: "text", fullWidth: true, variant: "outlined", value: host, onChange: function (e) { return setHost(e.target.value); } })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, variant: "contained", color: "primary", children: "Save" })] })] }), _jsx(Snackbar, { open: snackbarOpen, autoHideDuration: 6000, onClose: handleCloseSnackbar, children: _jsx(Alert, { onClose: handleCloseSnackbar, severity: "success", sx: { width: '100%' }, children: "LLM Configuration Created Successfully!" }) })] }));
};
export default AddLLMForm;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { Config, PixelStreaming } from '@epicgames-ps/mylib-pixelstreamingfrontend-ue5.3';
import ErrorBoundary from './ErrorBoundary';
import { SendEvent } from './SendEvent';
var PixelStreamingWrapper = forwardRef(function (_a, ref) {
    var initialSettings = _a.initialSettings, aiSpeechEvent = _a.aiSpeechEvent, callSession = _a.callSession;
    var videoParent = useRef(null);
    var _b = useState(), pixelStreaming = _b[0], setPixelStreaming = _b[1];
    var _c = useState(false), clickToPlayVisible = _c[0], setClickToPlayVisible = _c[1];
    console.log('PixelStreamingWrapper invoked');
    useImperativeHandle(ref, function () { return ({
        sendTranscript: sendTranscript,
        handleCancel: handleCancel,
        sendEvent: sendEvent
    }); });
    var sendEvent = function (event) {
        console.log('sendEvent called with:', event);
        if (pixelStreaming) {
            pixelStreaming.emitUIInteraction(event);
            console.log('Event sent:', event);
        }
        else {
            console.error('PixelStreaming instance not available, cannot send event');
        }
    };
    useEffect(function () {
        if (videoParent.current) {
            var config = new Config({ initialSettings: initialSettings });
            console.log('creating pixelstreaming instance');
            var streaming_1 = new PixelStreaming(config, {
                videoElementParent: videoParent.current
            });
            streaming_1.addEventListener('playStreamRejected', function () {
                setClickToPlayVisible(true);
            });
            console.log('setting pixelstreaming instance to ' + streaming_1);
            setPixelStreaming(streaming_1);
            return function () {
                try {
                    console.log('disconnecting pixelstreaming instance!');
                    streaming_1.disconnect();
                }
                catch (_a) {
                    console.error('error disconnecting pixelstreaming instance');
                }
            };
        }
        else {
            console.error('videoParent.current is null, not rendering');
        }
    }, [initialSettings]);
    var sendTranscript = function (quuid, transcript, selectedLanguage) {
        console.log("transcript: " + transcript);
        //print params
        console.log('sendTranscript called with:', quuid, transcript, selectedLanguage);
        // check if transcript is null
        var sendWithRetry = function (retryCount) {
            if (pixelStreaming) {
                if (transcript === null || transcript === undefined || transcript === "") {
                    console.log('Sending event:', new SendEvent('endrecording', callSession, quuid, 0.0, selectedLanguage, ""));
                    sendEvent(new SendEvent('endrecording', callSession, quuid, 0.0, selectedLanguage, ""));
                    console.log('Transcript empty sent');
                }
                else {
                    console.log('sending transcript: ' + transcript);
                    console.log('Sending event:', new SendEvent('endrecording', callSession, quuid, transcript.confidence, selectedLanguage, transcript.transcript));
                    console.log('transcript.transcript:', transcript.transcript);
                    console.log('transcript.confidence:', transcript.confidence);
                    sendEvent(new SendEvent('endrecording', callSession, quuid, transcript.confidence, selectedLanguage, transcript.transcript));
                    console.log('Transcript sent');
                }
            }
            else {
                if (retryCount > 0) {
                    console.error("Stream not available, retrying... (".concat(5 - retryCount + 1, "/5)"));
                    setTimeout(function () { return sendWithRetry(retryCount - 1); }, 200);
                }
                else {
                    console.error('Stream not available after 5 attempts, not sending transcript');
                }
            }
        };
        sendWithRetry(50); // Start with 5 retries
    };
    var handleCancel = function (uuid) {
        console.log('handleCancel called');
        // Logic to handle the cancellation
        if (pixelStreaming) {
            sendEvent(new SendEvent('cancelOutput', callSession, uuid));
            console.log('Cancel event sent to PixelStreaming');
        }
        else {
            console.error('PixelStreaming instance not available, cannot send cancel event');
        }
    };
    useEffect(function () {
        console.log('!stream variable changed: ' + pixelStreaming);
        if (pixelStreaming) {
            var handleResponse = function (data) {
                console.info("Response received!" + data);
                switch (data) {
                    case "StartedSpeechEvent":
                        console.log("received StartedSpeechEvent from UE5");
                        aiSpeechEvent("StartedSpeechEvent");
                        break;
                    case "EndedSpeechEvent":
                        console.log("received EndedSpeechEvent from UE5");
                        aiSpeechEvent("EndedSpeechEvent");
                        break;
                }
            };
            console.log('Adding response event listener to stream..');
            pixelStreaming.addResponseEventListener("handle_responses", handleResponse);
            return function () {
                console.log('Removing response event listener from stream..');
                pixelStreaming.removeResponseEventListener("handle_responses");
            };
        }
        else {
            console.log('Stream not available, not adding response event listener');
        }
    }, [pixelStreaming, aiSpeechEvent]);
    return (_jsx(ErrorBoundary, { children: _jsxs("div", { style: {
                width: '100%',
                height: '100%',
                position: 'relative'
            }, children: [_jsx("div", { style: {
                        width: '100%',
                        height: '100%'
                    }, ref: videoParent }), clickToPlayVisible && (_jsx("div", { style: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }, onClick: function () {
                        pixelStreaming === null || pixelStreaming === void 0 ? void 0 : pixelStreaming.play();
                        setClickToPlayVisible(false);
                    }, children: _jsx("div", { children: "Click to play" }) }))] }) }));
});
export default PixelStreamingWrapper;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "../routes"; // Adjust the import path as necessary
export var NavMobile = function () {
    var _a = useState(false), isOpen = _a[0], setOpen = _a[1];
    var ref = useRef(null);
    useClickAway(ref, function () { return setOpen(false); });
    var menuVariants = {
        open: {
            x: 0,
            opacity: 1,
            transition: { type: "spring", stiffness: 120, damping: 20 },
        },
        closed: {
            x: "-100%",
            opacity: 0,
            transition: { type: "spring", stiffness: 120, damping: 20 },
        },
    };
    return (_jsxs("div", { ref: ref, className: "lg:hidden", style: { zIndex: 1000 }, children: [_jsx("div", { style: {
                    backgroundColor: '#333', // Change this to your desired circle color
                    // Additional styles for centering the icon, if needed
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    margin: '20px',
                    marginLeft: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }, children: _jsx(Hamburger, { toggled: isOpen, toggle: setOpen, size: 20, color: "#FFF", hideOutline: false }) }), _jsx(AnimatePresence, { children: isOpen && (_jsx(motion.div, { initial: "closed", animate: "open", exit: "closed", variants: menuVariants, className: "absolute top-0 left-0 h-full", style: {
                        width: "250px",
                        zIndex: 1001,
                        backgroundColor: "#f0f0f0",
                        color: "#333",
                        boxShadow: "2px 0 5px rgba(0,0,0,0.3)"
                    }, children: _jsx("ul", { style: { listStyleType: "none", padding: "20px" }, children: routes.map(function (route) { return (_jsx("li", { style: { padding: "10px 0", borderBottom: "1px solid #ddd", display: "flex", alignItems: "center" }, children: _jsxs("a", { href: route.href, style: { textDecoration: "none", color: "#333", display: "flex", alignItems: "center" }, children: [_jsx(route.Icon, { style: { marginRight: "8px" } }), route.title] }) }, route.title)); }) }) })) })] }));
};

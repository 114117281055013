import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useKeycloak } from '@react-keycloak/web';
import { motion } from 'framer-motion';
var LoginButton = function () {
    var keycloak = useKeycloak().keycloak;
    // Capture the current URL
    var currentUrl = window.location.href;
    var handleLogin = function () {
        // Pass the current URL as the redirectUri for login
        keycloak.login({ redirectUri: currentUrl });
    };
    var handleLogout = function () {
        // Pass the current URL as the redirectUri for logout
        keycloak.logout({ redirectUri: currentUrl });
    };
    var handleRegister = function () {
        // Directly navigate to the registration page, Keycloak handles the redirect after registration
        window.location.href = "".concat(keycloak.createRegisterUrl({ redirectUri: currentUrl }));
    };
    // Define animation variants for the buttons
    var buttonVariants = {
        initial: {
            backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background initially
            color: '#FFF', // White text
            border: '1px solid #FFF', // Thinner white border
        },
        hover: {
            scale: 1.1,
            backgroundColor: '#333', // Solid background color on hover
            transition: { duration: 0.3 },
        },
        tap: {
            scale: 0.95,
        },
    };
    return (_jsx("div", { style: {
            position: 'absolute',
            right: '20px',
            top: '20px',
            display: 'flex',
            gap: '10px', // Adjust the space between buttons
        }, children: keycloak.authenticated ? (_jsx(motion.button, { initial: "initial", whileHover: "hover", whileTap: "tap", variants: buttonVariants, style: {
                borderRadius: '5px',
                padding: '10px 20px',
                cursor: 'pointer',
                outline: 'none',
            }, onClick: handleLogout, children: "Logout" })) : (_jsxs(_Fragment, { children: [_jsx(motion.button, { initial: "initial", whileHover: "hover", whileTap: "tap", variants: buttonVariants, style: {
                        borderRadius: '5px',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        outline: 'none',
                    }, onClick: handleLogin, children: "Login" }), _jsx(motion.button, { initial: "initial", whileHover: "hover", whileTap: "tap", variants: buttonVariants, style: {
                        borderRadius: '5px',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        outline: 'none',
                    }, onClick: handleRegister, children: "Sign Up" })] })) }));
};
export default LoginButton;

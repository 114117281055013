import { FaComments, // Chat icon
FaTags, // Tags, good for pricing
FaPhone, // For audio calls
FaVideo, // For video calls
FaUserPlus, // For creating an avatar or sign up related tasks
FaSignInAlt, // For login
FaList, // For list related tasks
FaInfoCircle, // For informational content, like an About page
 } from "react-icons/fa";
export var routes = [
    {
        title: "Video Call",
        href: "/",
        Icon: FaVideo, // Changed to video icon
    },
    {
        title: "Audio Call",
        href: "#",
        Icon: FaPhone, // Changed to phone icon for audio calls
    },
    {
        title: "Chat",
        href: "/chat",
        Icon: FaComments, // Suitable for chat
    },
    {
        title: "Pricing",
        href: "#",
        Icon: FaTags, // Suitable for pricing
    },
    {
        title: "Create Agent",
        href: "/newagent",
        Icon: FaUserPlus, // Changed to user plus, suitable for creating new user avatars or profiles
    },
    {
        title: "AgentList",
        href: "/agentlist",
        Icon: FaList, // Changed to user plus, suitable for creating new user avatars or profiles
    },
    {
        title: "Login",
        href: "#",
        Icon: FaSignInAlt, // Changed to sign-in icon, suitable for login
    },
    {
        title: "About",
        href: "/about",
        Icon: FaInfoCircle, // Changed to info circle, suitable for about pages
    },
];

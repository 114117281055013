// TODO: remove, this is only for testing purposes. Demo how to interact with agentcreator API
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LLMApi, Configuration, TTSApi, STTApi, AgentConfigurationApi } from 'agentcreator';
var apiConfig = new Configuration({ basePath: 'https://creator.callyour.ai/api/v1' });
export var createMyLLMConfiguration = function (vendor, model, requiresAPIKey, accessibleByAllUsers, host, defaultLlm) {
    // create a new LLM with these parameters
    console.log("Creating LLM Configuration:");
    var llmConfig = { vendor: vendor, model: model, requiresAPIKey: requiresAPIKey, accessibleByAllUsers: accessibleByAllUsers, host: host, defaultLlm: defaultLlm };
    var llmApi = new LLMApi(apiConfig);
    return llmApi.createLLMConfiguration({ lLM: llmConfig }).catch(function (error) {
        throw error;
    });
};
export var createTTSConfiguration = function (vendor, model, requiresAPIKey, defaultTts) {
    var ttsConfig = { vendor: vendor, model: model, requiresAPIKey: requiresAPIKey, defaultTts: defaultTts };
    var ttsApi = new TTSApi(apiConfig);
    return ttsApi.createTTSConfiguration({ tTS: ttsConfig }).catch(function (error) {
        throw error;
    });
};
export var createSTTConfiguration = function (vendor, model, requiresAPIKey, defaultStt) {
    var sttConfig = { vendor: vendor, model: model, requiresAPIKey: requiresAPIKey, defaultStt: defaultStt };
    var sttApi = new STTApi(apiConfig);
    return sttApi.createSTTConfiguration({ sTT: sttConfig }).catch(function (error) {
        throw error;
    });
};
export function createAgentWithConfiguration() {
    return __awaiter(this, void 0, void 0, function () {
        var llmId, ttsId, sttId, agentConfiguration, agentConfigurationApi;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createMyLLMConfiguration('OpenAI', 'GPT-3.5', true, true, null, false)];
                case 1:
                    llmId = _a.sent();
                    return [4 /*yield*/, createMyLLMConfiguration('OpenAI', 'GPT-4', true, true, null, true)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, createMyLLMConfiguration('OpenAI', 'GPT-4o', true, true, null, true)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, createMyLLMConfiguration('OpenAI', 'GPT-4o', true, true, null, true)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, createTTSConfiguration('Google', 'WaveNet', true, true)];
                case 5:
                    ttsId = _a.sent();
                    return [4 /*yield*/, createTTSConfiguration('Google', 'WaveNet-noapikey', false, false)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, createSTTConfiguration('OpenAI', 'Whisper-3', true, true)];
                case 7:
                    sttId = _a.sent();
                    return [4 /*yield*/, createSTTConfiguration('OpenAI', 'Whisper-0.0', true, false)];
                case 8:
                    _a.sent();
                    agentConfiguration = {
                        name: 'Lisa',
                        welcomeMessage: 'Hello, how can I help you today?',
                        systemPrompt: "You are Lisa, an agent in a browser window",
                        supportsText: true,
                        supportsAudio: true,
                        supportsVideo: true,
                        llm: llmId.id,
                        tts: ttsId.id,
                        stt: sttId.id,
                    };
                    console.log("Creating Agent Configuration:", agentConfiguration);
                    agentConfigurationApi = new AgentConfigurationApi(apiConfig);
                    return [2 /*return*/, agentConfigurationApi.createAgentConfiguration({ agentConfiguration: agentConfiguration }).catch(function (error) {
                            throw error;
                        })];
            }
        });
    });
}

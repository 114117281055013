var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import PixelStreamingWrapper from './PixelStreamingWrapper';
import './AIVideoCall.css';
// import 'regenerator-runtime/runtime';
import { SendEvent } from './SendEvent';
import { v4 as uuidv4 } from 'uuid';
import MicButton from './MicButton';
import { languages } from './languages';
var isMobileDevice = function () {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};
var isSpeechRecognitionSupported = function () { return 'webkitSpeechRecognition' in window || 'SpeechRecognition' in window; };
// both, speech recognition + useMic is not supported on (Android) mobile devices. On firefox, useMic has to be true, because webspeech is not supported.
var shouldUseMic = function () { return !(isMobileDevice() && isSpeechRecognitionSupported()); };
var defaultSettings = {
    AutoPlayVideo: true,
    AutoConnect: true,
    StartVideoMuted: false,
    WaitForStreamer: true,
    HoveringMouse: true,
    UseMic: shouldUseMic(),
    KeyboardInput: false,
    MouseInput: false,
    TouchInput: false,
    GameInput: false,
    XRControllerInput: false,
    MatchViewportRes: true,
    FakeMouseWithTouches: false,
    AFKTimeout: 120,
    TimeoutIfIdle: true,
    PreferredCodec: "H264",
    ss: "wss://callyour.ai", // Uncomment for production use 
    // ss: "ws://localhost:81", // Uncomment for production use 
    // ss: "ws://localhost:80", // Uncomment for production use 
};
var AIVideoCall = React.memo(function (_a) {
    var _b = _a.videoCallSettings, videoCallSettings = _b === void 0 ? defaultSettings : _b, callSession = _a.callSession;
    console.trace('AIVideoCall invoked with callSession:', callSession);
    var _c = useState(null), recognition = _c[0], setRecognition = _c[1];
    var _d = useState(null), transcript = _d[0], setTranscript = _d[1];
    var uuidRef = useRef(uuidv4());
    var _e = useState('en-US'), selectedLanguage = _e[0], setSelectedLanguage = _e[1]; // Default language set to English
    var pixelStreamingRef = useRef(null);
    var micButtonRef = useRef(null);
    var setUuid = function (newUuid) {
        uuidRef.current = newUuid;
    };
    useEffect(function () {
        console.log('Transcript updated:', transcript);
        if (transcript !== null) {
            console.log('Sending transcript:', transcript);
            pixelStreamingRef.current.sendTranscript(uuidRef.current, transcript, selectedLanguage);
        }
    }, [transcript]);
    useEffect(function () {
        var checkMicrophonePermissions = function () { return __awaiter(void 0, void 0, void 0, function () {
            var permissionStatus, err_1, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, navigator.permissions.query({ name: 'microphone' })];
                    case 1:
                        permissionStatus = _a.sent();
                        if (permissionStatus.state === 'denied') {
                            console.error('Microphone access denied. Please enable it in the browser settings.');
                            return [2 /*return*/];
                        }
                        if (!(permissionStatus.state === 'granted')) return [3 /*break*/, 2];
                        console.log('Microphone access granted.');
                        initializeSpeechRecognition();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(permissionStatus.state === 'prompt')) return [3 /*break*/, 6];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ audio: true })];
                    case 4:
                        _a.sent();
                        console.log('Microphone access granted after prompt.');
                        initializeSpeechRecognition();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        console.error('Microphone access denied after prompt.');
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_2 = _a.sent();
                        console.error('Error checking microphone permissions:', err_2);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        var initializeSpeechRecognition = function () {
            console.log('Initializing speech recognition...');
            if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
                console.log("SpeechRecognition in window:", ('SpeechRecognition' in window));
                console.log("webkitSpeechRecognition in window:", ('webkitSpeechRecognition' in window));
                var recognitionInstance = new window.webkitSpeechRecognition();
                console.log('Recognition instance:', recognitionInstance);
                recognitionInstance.continuous = false;
                recognitionInstance.interimResults = false;
                recognitionInstance.lang = selectedLanguage;
                recognitionInstance.onspeechend = function () {
                    console.log('Speech recognition speech end');
                };
                recognitionInstance.onsoundend = function () {
                    console.log('Speech recognition sound end');
                };
                recognitionInstance.onsoundstart = function () {
                    console.log('Speech recognition sound start');
                };
                var recognitionTimeout_1;
                var hasTranscript_1 = false;
                var startTimeout_1 = function () {
                    console.log('Starting timeout of 1s.. afterwards speech will be discarded');
                    setTimeout(function () {
                        if (!hasTranscript_1) {
                            console.log('No speech was recognized, hasTranscript:', hasTranscript_1);
                            pixelStreamingRef.current.sendTranscript(uuidRef.current, "", selectedLanguage);
                            console.log('sent empty transcript');
                            micButtonRef.current.setWaitingForAnswer();
                            console.log('set button to waiting for answer');
                        }
                    }, 1000); // Adjust the timeout duration as needed
                };
                var clearTimeoutIfNeeded_1 = function () {
                    console.log('Clearing timeout..');
                    if (recognitionTimeout_1) {
                        console.log('Recognition timeout..');
                        clearTimeout(recognitionTimeout_1);
                        recognitionTimeout_1 = null;
                    }
                };
                recognitionInstance.onresult = function (event) {
                    console.log('Speech recognition result:', event);
                    clearTimeoutIfNeeded_1();
                    hasTranscript_1 = true;
                    var lastResultIndex = event.results.length - 1;
                    var transcriptResult = event.results[lastResultIndex][0];
                    console.log('Transcript:', transcriptResult);
                    setTranscript(transcriptResult);
                };
                recognitionInstance.onnomatch = function (event) {
                    console.log('No match:', event);
                };
                recognitionInstance.onaudioend = function () {
                    console.log('Speech recognition audio ended');
                };
                recognitionInstance.onaudiostart = function () {
                    console.log('Speech recognition audio started');
                };
                recognitionInstance.onspeechstart = function () {
                    console.log('Speech recognition started');
                };
                recognitionInstance.onend = function () {
                    console.log('Speech recognition ended');
                    startTimeout_1();
                };
                recognitionInstance.onerror = function (event) {
                    console.error('Speech recognition error:', event.error);
                    clearTimeoutIfNeeded_1();
                };
                recognitionInstance.onstart = function () {
                    console.log('Speech recognition started');
                    hasTranscript_1 = false;
                    clearTimeoutIfNeeded_1();
                };
                setRecognition(recognitionInstance);
                // recognition.start();
                // Cleanup timeout on unmount
                return function () {
                    clearTimeoutIfNeeded_1();
                };
            }
            else {
                console.log("Speech recognition API not supported.");
            }
        };
        // initializeSpeechRecognition();
        checkMicrophonePermissions();
        return function () {
            if (recognition) {
                console.log('Cleaning up recognition instance.., stopping to listen');
                recognition.stop();
            }
        };
    }, [selectedLanguage]);
    var startListening = function () {
        console.log('Start listening and sending startrecordingevent');
        var newUuid = uuidv4();
        setUuid(newUuid);
        if (pixelStreamingRef.current) {
            console.log('Sending start recording event with uuid ', newUuid);
            pixelStreamingRef.current.sendEvent(new SendEvent('startrecording', callSession, newUuid));
        }
        if (recognition) {
            console.log('Recognition instance:', recognition);
            // try & catch block to prevent error when starting recognition after it has been stopped
            try {
                console.log('Starting recognition with language:', selectedLanguage);
                recognition.lang = selectedLanguage;
                recognition.start();
            }
            catch (error) {
                console.error('Error starting recognition:', error);
            }
        }
    };
    var stopListening = function () {
        console.log('Stop listening and sending stoplisteningevent');
        if (recognition) {
            setTimeout(function () {
                recognition.stop();
            }, 100);
        }
        else if (pixelStreamingRef.current) {
            console.log('Sending end recording event with uuid ', uuidRef.current);
            pixelStreamingRef.current.sendEvent(new SendEvent('endrecording', callSession, uuidRef.current, 0.0, selectedLanguage, ''));
        }
    };
    var handleLanguageChange = function (event) {
        setSelectedLanguage(event.target.value);
    };
    var aiSpeechEvent = function (event) {
        console.log("AI Speech Event: ".concat(event));
        micButtonRef.current.handleAISpeechEvent(event);
    };
    return (_jsxs("div", { style: { position: 'relative', width: '100%', height: '100%' }, children: [_jsx("div", { id: "language-selection", className: 'language-selection', children: _jsx("select", { onChange: handleLanguageChange, value: selectedLanguage, children: languages.map(function (language) { return (_jsx("option", { value: language.code, children: language.label }, language.code)); }) }) }), _jsx(PixelStreamingWrapper, { initialSettings: videoCallSettings, callSession: callSession, aiSpeechEvent: aiSpeechEvent, ref: pixelStreamingRef }), _jsx(MicButton, { ref: micButtonRef, startListening: startListening, stopListening: stopListening, handleCancelOutput: function () { return pixelStreamingRef.current.handleCancel(uuidRef.current); } })] }));
});
export default AIVideoCall;

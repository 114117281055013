import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { App } from './components/App';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
var keycloakConfig = {
    url: 'https://login.callyour.ai',
    realm: 'callyourai',
    clientId: 'react',
};
var keycloak = new Keycloak(keycloakConfig);
document.body.onload = function () {
    var rootElement = document.getElementById('root');
    // Ensure the root element exists before rendering
    if (rootElement) {
        createRoot(rootElement).render(_jsx(ReactKeycloakProvider, { authClient: keycloak, children: _jsx(App, {}) }));
    }
    else {
        console.error('Failed to find the root element');
    }
};

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var AgentLinksPopup = function (_a) {
    var open = _a.open, onClose = _a.onClose, links = _a.links, agentName = _a.agentName;
    var navigate = useNavigate();
    var handleClose = function () {
        onClose(); // Call the original close function
        navigate('/agentlist'); // Navigate to /agentlist
    };
    return (_jsxs(Dialog, { open: open, onClose: handleClose, children: [_jsxs(DialogTitle, { children: ["Interact with ", agentName] }), _jsx(DialogContent, { children: _jsxs("div", { children: [_jsxs("p", { children: ["Chat with ", agentName, ": ", _jsx("a", { href: links.textUrl, children: links.textUrl })] }), _jsxs("p", { children: ["Video call ", agentName, ": ", _jsx("a", { href: links.videoUrl, children: links.videoUrl })] })] }) }), _jsx(DialogActions, { children: _jsx(Button, { onClick: handleClose, children: "Close" }) })] }));
};
export default AgentLinksPopup;

var SendEvent = /** @class */ (function () {
    // Implementation signature
    function SendEvent(type, call_session, uuid, confidence, language, transcript) {
        console.log("SendEvent constructor called, uuid is " + uuid + ", uuid.tostring=" + uuid.toString());
        this.type = type;
        this.call_session = call_session;
        this.uuid = uuid;
        this.timestamp = Date.now();
        if (confidence !== undefined) {
            this.confidence = confidence;
        }
        if (language !== undefined) {
            this.language = language;
        }
        if (transcript !== undefined) {
            this.transcript = transcript;
        }
        console.log("SendEvent created: with this transcript: " + transcript);
    }
    SendEvent.prototype.toJSON = function () {
        console.log("SendEvent toJSON called, transcript is " + encodeURIComponent(this.transcript) + " type = " + this.type + ", uuid=" + this.uuid);
        return {
            type: this.type,
            call_session: this.call_session,
            uuid: this.uuid.toString(),
            timestamp: this.timestamp,
            confidence: this.confidence,
            language: this.language,
            transcript: encodeURIComponent(this.transcript)
        };
    };
    return SendEvent;
}());
export { SendEvent };

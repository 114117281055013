import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
var DeletionDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, onDelete = _a.onDelete, error = _a.error;
    return (_jsxs(Dialog, { open: open, onClose: onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: "Confirm Deletion" }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { id: "alert-dialog-description", children: "Are you sure you want to delete this agent? This action cannot be undone." }), error && _jsx("p", { style: { color: 'red' }, children: error })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: onDelete, autoFocus: true, children: "Delete" })] })] }));
};
export default DeletionDialog;

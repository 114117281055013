import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { Box, Grid, Card, Button, Avatar, Typography, IconButton, CardActions, CardContent } from '@mui/material';
import { VideoCall as VideoCallIcon, Call as CallIcon, Message as MessageIcon, Share as ShareIcon } from '@mui/icons-material';
var AgentCard = function (_a) {
    var agent = _a.agent, onDelete = _a.onDelete;
    var navigate = useNavigate();
    var baseUrl = window.location.origin;
    var urls = {
        textUrl: "".concat(baseUrl, "/chat?agentid=").concat(agent.id),
        audioUrl: "".concat(baseUrl, "/audio?agentid=").concat(agent.id),
        videoUrl: "".concat(baseUrl, "?agentid=").concat(agent.id),
    };
    var getAvatarUrl = function (id) { return "/assets/images/profilepicture.png"; };
    var handleCardClick = function () {
        navigate("/agent/edit/".concat(agent.id));
    };
    var handleEdit = function (e) {
        e.stopPropagation();
        navigate("/agent/edit/".concat(agent.id));
    };
    var handleOpenUrl = function (url) {
        window.open(url, '_blank');
    };
    var _b = useState(false), popupOpen = _b[0], setPopupOpen = _b[1];
    var handleShareClick = function (event) {
        event.stopPropagation(); // Prevent the card click event
        setPopupOpen(true);
    };
    return (_jsxs(Card, { sx: {
            display: 'flex',
            flexDirection: 'row',
            width: 1, // currently takes the full width of its container
            maxWidth: 600, // sets the maximum width of each card
            m: 2,
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)'
        }, onClick: handleCardClick, children: [_jsxs(Box, { sx: {
                    width: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }, children: [_jsx(Avatar, { sx: {
                            bgcolor: blue[500],
                            width: 100,
                            height: 100,
                            mb: 1 // This margin-bottom pushes the icons down a bit from the avatar.
                        }, src: getAvatarUrl(agent.id), alt: agent.name }), _jsxs(Grid, { container: true, spacing: 1, alignItems: "center", justifyContent: "center", sx: { p: 1 }, children: [_jsx(Grid, { item: true, children: _jsx(IconButton, { color: "primary", onClick: function () { return handleOpenUrl(urls.videoUrl); }, children: _jsx(VideoCallIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { color: "primary", onClick: function () { return handleOpenUrl(urls.audioUrl); }, children: _jsx(CallIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { color: "primary", onClick: function () { return handleOpenUrl(urls.textUrl); }, children: _jsx(MessageIcon, {}) }) })] })] }), _jsxs(Box, { sx: {
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }, children: [_jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { p: 1 }, children: [_jsxs(Grid, { item: true, xs: true, children: [_jsx(Typography, { variant: "h6", children: agent.name }), _jsx(Typography, { variant: "body2", color: "text.secondary", sx: { textAlign: 'left', flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.75rem' }, children: "".concat(agent.sessions, " sessions, ").concat(agent.minutes, " minutes") })] }), _jsx(Grid, { item: true, children: _jsx(IconButton, { color: "primary", onClick: handleShareClick, children: _jsx(ShareIcon, {}) }) })] }), _jsx(CardContent, { children: _jsx(Typography, { variant: "body2", color: "text.secondary", style: {
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                height: '4.5em',
                                textAlign: 'left',
                                paddingRight: '20px',
                            }, children: agent.systemPrompt }) }), _jsxs(CardActions, { sx: { justifyContent: 'flex-end' }, children: [_jsx(Button, { size: "small", onClick: handleEdit, children: "Edit" }), _jsx(Button, { size: "small", onClick: function (e) { e.stopPropagation(); onDelete(); }, children: "Delete" })] })] })] }));
};
export default AgentCard;

import { useEffect } from 'react';
// Export the ExternalRedirect component
export var ExternalRedirect = function (_a) {
    var to = _a.to;
    useEffect(function () {
        window.location.href = to;
        // or use window.open(to, '_blank') for opening in a new tab
    }, [to]);
    return null; // This component does not render anything
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DeepChat } from 'deep-chat-react';
var initialMessages = [
    { role: 'user', text: 'Hey, how are you today?' },
    { role: 'ai', text: 'I am doing very well!' },
];
var defaultAgentUUID = 'cd706ce6-23db-4252-8100-50a63b00e962'; // Predefined UUID
var getAgentUUIDFromURL = function () {
    var params = new URLSearchParams(window.location.search);
    return params.get('agentId') || defaultAgentUUID;
};
var ChatPage = function (props) {
    var _a = useState(''), userUUID = _a[0], setUserUUID = _a[1];
    var _b = useState(defaultAgentUUID), agentUUID = _b[0], setAgentUUID = _b[1];
    useEffect(function () {
        // Generate and set the user UUID
        setUserUUID(uuidv4());
        // Set the agent UUID from URL or fallback to default
        setAgentUUID(getAgentUUIDFromURL());
    }, []);
    return (_jsxs("div", { className: "components", style: { height: '100%' }, children: [_jsx("div", { className: "diagonal-line", style: { background: '#e8f5ff' } }), _jsx(DeepChat, { style: {
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    padding: '40px',
                    display: 'flex',
                    border: '1px solid #e4e4e4',
                    background: 'linear-gradient(90deg, rgb(239, 242, 247) 0%, rgb(237, 240, 249) 15.2057%, rgb(235, 239, 248) 26.297%, rgb(231, 237, 249) 47.585%, rgb(230, 236, 250) 48.6583%, rgb(228, 236, 249) 57.6029%, rgb(227, 234, 250) 65.4741%, rgb(222, 234, 250) 72.093%, rgb(219, 230, 248) 79.4275%, rgb(216, 229, 248) 86.2254%, rgb(213, 228, 249) 89.4454%, rgb(210, 226, 249) 94.2755%, rgb(209, 225, 248) 96.6011%, rgb(208, 224, 247) 100%)',
                }, initialMessages: initialMessages, introMessage: { text: 'Send a chat message to the agent.' }, 
                // request={{url: 'https://chat.callyour.ai/chat-stream'}}
                request: { url: 'http://localhost:8082/chat-stream' }, stream: true, microphone: true, messageStyles: {
                    default: {
                        shared: {
                            bubble: {
                                backgroundColor: 'unset',
                                marginTop: '10px',
                                marginBottom: '10px',
                                boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)',
                            },
                        },
                        user: {
                            bubble: {
                                background: 'linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%)',
                            },
                        },
                        ai: {
                            bubble: {
                                background: 'rgba(255,255,255,0.7)',
                            },
                        },
                    },
                }, textInput: {
                    placeholder: { text: 'Write your message!' },
                    styles: {
                        container: {
                            borderRadius: '20px',
                            border: 'unset',
                            width: '78%',
                            marginLeft: '-15px',
                            boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)',
                        },
                        text: {
                            padding: '10px',
                            paddingLeft: '15px',
                            paddingRight: '34px',
                        },
                    },
                }, requestInterceptor: function (details) {
                    // Modifying the request body to include the UUIDs
                    var modifiedBody = __assign(__assign({}, details.body), { agentUUID: agentUUID, userUUID: userUUID });
                    return __assign(__assign({}, details), { body: modifiedBody });
                } })] }));
};
export default ChatPage;
{ /* <DeepChat
  style={{borderRadius: '10px'}}
  introMessage={{text: 'Send files to an example server.'}}
  request={{url: 'https://creator.callyour.ai/files'}}
  audio={true}
  images={true}
  gifs={true}
  camera={true}
  microphone={true}
  mixedFiles={true}
  textInput={{placeholder: {text: 'Send a file!'}}}
  validateInput={(_?: string, files?: File[]) => {
    return !!files && files.length > 0;
  }}
/> */
}
{ /* by setting maxMessages requestBodyLimits to 0 or lower - each request will send full chat history:
https://deepchat.dev/docs/connect/#requestBodyLimits */
}
{ /* If you don't want to or can't edit the target service, you can process the outgoing message using
    responseInterceptor and the incoming message using responseInterceptor:
    https://deepchat.dev/docs/interceptors */
}
{ /*                 <DeepChat
//                   style={{borderRadius: '10px'}}
//                   introMessage={{text: 'Send a chat message to an example server.'}}
//                   request={{url: 'https://creator.callyour.ai/chat'}}
//                   requestBodyLimits={{maxMessages: -1}}
//                   requestInterceptor={(details: RequestDetails) => {
//                     console.log(details);
//                     return details;
//                   }}
//                   responseInterceptor={(response: any) => {
//                     console.log(response);
//                     return response;
//                   }}
//                 />*/
}

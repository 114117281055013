import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Topbar } from "./topbar";
import ChatPage from "./Pages/ChatPage/ChatPage";
import CreatePage from "./Pages/AgentPages/CreatePage";
import EditCreateAgentPage from "./Pages/AgentPages/EditCreateAgentPage";
import AgentListPage from "./Pages/AgentPages/AgentListPage";
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import { ExternalRedirect } from './ExternalRedirect';
import AIVideoCall from "./Pages/VideoPage/components/AIVideoCall";
import LoginButton from './LoginButton'; // Import the LoginButton component
var StyledContainer = function (_a) {
    var children = _a.children;
    return (_jsx("div", { style: {
            paddingTop: '100px',
            paddingBottom: '30px',
            paddingLeft: '100px',
            paddingRight: '100px',
            height: 'calc(100% - 200px)',
            width: 'calc(100% - 250px)'
        }, children: children }));
};
var VideoCallRoute = function () {
    var searchParams = useSearchParams()[0];
    var userId = searchParams.get('userid') || '598346a6-5cb9-40fe-9f97-f128cb516459';
    var agentId = searchParams.get('agentid') || '7c7b9737-a91a-4b2e-add8-40116659776b';
    console.log('videocallroute invoked');
    return (_jsx(AIVideoCall, { callSession: { userId: userId, agentId: agentId } }));
};
export var App = function () {
    console.log('App invoked');
    return (_jsx("div", { style: { height: '100%', display: 'relative', width: '100%' }, children: _jsxs(BrowserRouter, { children: [_jsxs("div", { style: { zIndex: 1, position: 'fixed', width: '100%' }, children: [_jsx(Topbar, {}), _jsx(LoginButton, {}), " "] }), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(VideoCallRoute, {}) }), _jsx(Route, { path: "chat", element: _jsx(StyledContainer, { children: _jsx(ChatPage, {}) }) }), _jsx(Route, { path: "create", element: _jsx(StyledContainer, { children: _jsx(CreatePage, {}) }) }), _jsx(Route, { path: "agentlist", element: _jsx(StyledContainer, { children: _jsx(AgentListPage, {}) }) }), _jsx(Route, { path: "agent/edit/:agentId", element: _jsxs(StyledContainer, { children: [_jsx(EditCreateAgentPage, {}), " "] }) }), _jsx(Route, { path: "newagent", element: _jsx(StyledContainer, { children: _jsx(EditCreateAgentPage, {}) }) }), _jsx(Route, { path: "About", element: _jsx(ExternalRedirect, { to: "https://www.linkedin.com/in/jonas-willaredt-276001a8/" }) }), _jsx(Route, { path: "health", element: _jsx("h3", { children: "Healthy" }) })] })] }) }));
};

// languages.ts
export var languages = [
    { code: 'af-ZA', label: 'Afrikaans', flag: '🇿🇦' },
    { code: 'az-AZ', label: 'Azərbaycanca', flag: '🇦🇿' },
    { code: 'bn-BD', label: 'বাংলা (BD)', flag: '🇧🇩' },
    { code: 'bn-IN', label: 'বাংলা (IN)', flag: '🇮🇳' },
    { code: 'ca-ES', label: 'Català', flag: '🇪🇸' },
    { code: 'cs-CZ', label: 'Čeština', flag: '🇨🇿' },
    { code: 'da-DK', label: 'Dansk', flag: '🇩🇰' },
    { code: 'de-DE', label: 'Deutsch', flag: '🇩🇪' },
    { code: 'en-AU', label: 'English (AU)', flag: '🇦🇺' },
    { code: 'en-CA', label: 'English (CA)', flag: '🇨🇦' },
    { code: 'en-IN', label: 'English (IN)', flag: '🇮🇳' },
    { code: 'en-KE', label: 'English (KE)', flag: '🇰🇪' },
    { code: 'en-TZ', label: 'English (TZ)', flag: '🇹🇿' },
    { code: 'en-GH', label: 'English (GH)', flag: '🇬🇭' },
    { code: 'en-NZ', label: 'English (NZ)', flag: '🇳🇿' },
    { code: 'en-NG', label: 'English (NG)', flag: '🇳🇬' },
    { code: 'en-ZA', label: 'English (ZA)', flag: '🇿🇦' },
    { code: 'en-PH', label: 'English (PH)', flag: '🇵🇭' },
    { code: 'en-GB', label: 'English (GB)', flag: '🇬🇧' },
    { code: 'en-US', label: 'English (US)', flag: '🇺🇸' },
    { code: 'es-AR', label: 'Español (AR)', flag: '🇦🇷' },
    { code: 'es-BO', label: 'Español (BO)', flag: '🇧🇴' },
    { code: 'es-CL', label: 'Español (CL)', flag: '🇨🇱' },
    { code: 'es-CO', label: 'Español (CO)', flag: '🇨🇴' },
    { code: 'es-CR', label: 'Español (CR)', flag: '🇨🇷' },
    { code: 'es-EC', label: 'Español (EC)', flag: '🇪🇨' },
    { code: 'es-SV', label: 'Español (SV)', flag: '🇸🇻' },
    { code: 'es-ES', label: 'Español (ES)', flag: '🇪🇸' },
    { code: 'es-US', label: 'Español (US)', flag: '🇺🇸' },
    { code: 'es-GT', label: 'Español (GT)', flag: '🇬🇹' },
    { code: 'es-HN', label: 'Español (HN)', flag: '🇭🇳' },
    { code: 'es-MX', label: 'Español (MX)', flag: '🇲🇽' },
    { code: 'es-NI', label: 'Español (NI)', flag: '🇳🇮' },
    { code: 'es-PA', label: 'Español (PA)', flag: '🇵🇦' },
    { code: 'es-PY', label: 'Español (PY)', flag: '🇵🇾' },
    { code: 'es-PE', label: 'Español (PE)', flag: '🇵🇪' },
    { code: 'es-PR', label: 'Español (PR)', flag: '🇵🇷' },
    { code: 'es-DO', label: 'Español (DO)', flag: '🇩🇴' },
    { code: 'es-UY', label: 'Español (UY)', flag: '🇺🇾' },
    { code: 'es-VE', label: 'Español (VE)', flag: '🇻🇪' },
    { code: 'gl-ES', label: 'Galego', flag: '🇪🇸' },
    { code: 'fr-FR', label: 'Français', flag: '🇫🇷' },
    { code: 'hr-HR', label: 'Hrvatski', flag: '🇭🇷' },
    { code: 'is-IS', label: 'Íslenska', flag: '🇮🇸' },
    { code: 'it-IT', label: 'Italiano (IT)', flag: '🇮🇹' },
    { code: 'it-CH', label: 'Italiano (CH)', flag: '🇨🇭' },
    { code: 'kn-IN', label: 'ಕನ್ನಡ', flag: '🇮🇳' },
    { code: 'lv-LV', label: 'Latviešu', flag: '🇱🇻' },
    { code: 'lt-LT', label: 'Lietuvių', flag: '🇱🇹' },
    { code: 'ml-IN', label: 'മലയാളം', flag: '🇮🇳' },
    { code: 'mr-IN', label: 'मराठी', flag: '🇮🇳' },
    { code: 'hu-HU', label: 'Magyar', flag: '🇭🇺' },
    { code: 'nl-NL', label: 'Nederlands', flag: '🇳🇱' },
    { code: 'ne-NP', label: 'नेपाली भाषा', flag: '🇳🇵' },
    { code: 'nb-NO', label: 'Norsk bokmål', flag: '🇳🇴' },
    { code: 'pl-PL', label: 'Polski', flag: '🇵🇱' },
    { code: 'pt-BR', label: 'Português (BR)', flag: '🇧🇷' },
    { code: 'pt-PT', label: 'Português (PT)', flag: '🇵🇹' },
    { code: 'ro-RO', label: 'Română', flag: '🇷🇴' },
    { code: 'si-LK', label: 'සිංහල', flag: '🇱🇰' },
    { code: 'sl-SI', label: 'Slovenščina', flag: '🇸🇮' },
    { code: 'sk-SK', label: 'Slovenčina', flag: '🇸🇰' },
    { code: 'fi-FI', label: 'Suomi', flag: '🇫🇮' },
    { code: 'sv-SE', label: 'Svenska', flag: '🇸🇪' },
    { code: 'sw-TZ', label: 'Kiswahili (TZ)', flag: '🇹🇿' },
    { code: 'sw-KE', label: 'Kiswahili (KE)', flag: '🇰🇪' },
    { code: 'ta-IN', label: 'தமிழ் (IN)', flag: '🇮🇳' },
    { code: 'ta-SG', label: 'தமிழ் (SG)', flag: '🇸🇬' },
    { code: 'ta-LK', label: 'தமிழ் (LK)', flag: '🇱🇰' },
    { code: 'ta-MY', label: 'தமிழ் (MY)', flag: '🇲🇾' },
    { code: 'te-IN', label: 'తెలుగు', flag: '🇮🇳' },
    { code: 'vi-VN', label: 'Tiếng Việt', flag: '🇻🇳' },
    { code: 'tr-TR', label: 'Türkçe', flag: '🇹🇷' },
    { code: 'ur-PK', label: 'اُردُو (PK)', flag: '🇵🇰' },
    { code: 'ur-IN', label: 'اُردُو (IN)', flag: '🇮🇳' },
    { code: 'el-GR', label: 'Ελληνικά', flag: '🇬🇷' },
    { code: 'bg-BG', label: 'български', flag: '🇧🇬' },
    { code: 'ru-RU', label: 'Pусский', flag: '🇷🇺' },
    { code: 'sr-RS', label: 'Српски', flag: '🇷🇸' },
    { code: 'uk-UA', label: 'Українська', flag: '🇺🇦' },
    { code: 'ko-KR', label: '한국어', flag: '🇰🇷' },
    { code: 'cmn-Hans-CN', label: '中文 (CN)', flag: '🇨🇳' },
    { code: 'cmn-Hans-HK', label: '中文 (HK)', flag: '🇭🇰' },
    { code: 'cmn-Hant-TW', label: '中文 (TW)', flag: '🇹🇼' },
    { code: 'yue-Hant-HK', label: '粵語 (HK)', flag: '🇭🇰' },
    { code: 'ja-JP', label: '日本語', flag: '🇯🇵' },
    { code: 'hi-IN', label: 'हिन्दी', flag: '🇮🇳' },
    { code: 'th-TH', label: 'ภาษาไทย', flag: '🇹🇭' }
];

import { jsx as _jsx } from "react/jsx-runtime";
// TODO: remove. This is only for testing purposes. Demo how to interact with agentcreator API
import { useEffect } from 'react';
// Assuming Agentcreator is used somewhere, though it's not directly used in this snippet.
var Agentcreator = require('agentcreator');
import { createAgentWithConfiguration } from './InitialAgentCreationType'; // Ensure the path is correct
var CreatePage = function () {
    useEffect(function () {
        createAgentWithConfiguration().then(function (data) {
            console.log('Agent Configuration Created:', data);
        });
    }, []); // Add dependencies in this array, if any
    // Return statement should be outside of the useEffect hook
    return (_jsx("div", { className: "components", children: _jsx("div", { className: "diagonal-line", style: { background: '#e8f5ff' } }) }));
};
export default CreatePage;

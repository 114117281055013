import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
var MicButton = forwardRef(function (_a, ref) {
    var startListening = _a.startListening, stopListening = _a.stopListening, handleCancelOutput = _a.handleCancelOutput;
    var _b = useState(false), isListening = _b[0], setIsListening = _b[1];
    var _c = useState(false), isTalking = _c[0], setIsTalking = _c[1];
    var _d = useState(false), isLongPress = _d[0], setIsLongPress = _d[1];
    var _e = useState(false), isFinishedListening = _e[0], setIsFinishedListening = _e[1];
    var pressTimer = useRef(null);
    var isPressing = useRef(false);
    useImperativeHandle(ref, function () { return ({
        setWaitingForAnswer: function () {
            setIsFinishedListening(true);
            setIsListening(false);
            setIsTalking(false);
        },
        handleAISpeechEvent: function (param) {
            // check if para is "StartedSpeechEvent"
            console.log('Micbutton: handleAISpeechEvent called with:', param);
            if (param == "StartedSpeechEvent") {
                setIsListening(false);
                setIsFinishedListening(true);
                setIsTalking(true);
            }
            else if (param == "EndedSpeechEvent") {
                setIsTalking(false);
                setIsFinishedListening(false);
                setIsListening(false);
            }
        }
    }); });
    var handleMicButtonPress = function () {
        console.log('Mic button pressed');
        if (isListening) {
            console.log('Stop listening, because micbutton was in listening state');
            stopListening();
            isPressing.current = false;
            setIsLongPress(false);
            setIsListening(false);
            setIsFinishedListening(true);
            setIsTalking(true);
            return;
        }
        setIsLongPress(false);
        setIsTalking(false);
        setIsListening(true);
        setIsFinishedListening(false);
        isPressing.current = true;
        startListening();
        pressTimer.current = setTimeout(function () {
            if (isPressing.current) {
                console.log('Long press');
                setIsLongPress(true);
            }
            else {
                // short press
                console.log('Short press');
                setIsLongPress(false);
            }
        }, 1000);
    };
    var handleMicButtonRelease = function () {
        console.log('Mic button released');
        isPressing.current = false;
        if (pressTimer.current) {
            clearTimeout(pressTimer.current);
        }
        if (isLongPress) {
            console.log('Long press release - stop listening');
            stopListening();
            isPressing.current = false;
            setIsTalking(true);
            setIsLongPress(false);
            setIsListening(false);
            setIsFinishedListening(true);
        }
    };
    var handleCancelClicked = function () {
        setIsFinishedListening(false);
        setIsListening(false);
        setIsTalking(false);
        handleCancelOutput();
    };
    return (_jsxs("div", { className: "mic-container", children: [_jsxs("div", { className: "spinner-container ".concat(isFinishedListening && !isTalking ? 'visible' : ''), children: [_jsx("div", { className: "dot" }), _jsx("div", { className: "dot" }), _jsx("div", { className: "dot" })] }), _jsx("div", { className: "listening-text ".concat(isListening ? 'visible' : ''), children: "Listening..." }), _jsx("button", { id: "micButton", className: "mic-button ".concat(isListening ? 'mic-button-red' : ''), onMouseDown: handleMicButtonPress, onMouseUp: handleMicButtonRelease, onTouchStart: handleMicButtonPress, onTouchEnd: handleMicButtonRelease, style: { display: isTalking || isFinishedListening ? 'none' : 'inline-block' }, children: _jsx("i", { className: "fas ".concat(isListening ? 'fa-stop' : 'fa-microphone') }) }), _jsx("button", { id: "cancelButton", className: "cancel-button ".concat(isFinishedListening ? '' : 'hidden'), onClick: handleCancelClicked, children: _jsx("i", { className: "fas fa-times" }) })] }));
});
export default MicButton;
